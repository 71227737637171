/*********** Css Animation *********/




/******** Sub menu *******/

/* #mega {
  position: absolute;
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.32);
  margin-top: 2px !important;
  z-index: 999999;
  background: #fff;
  padding: 15px 0;
  border-radius: 10px;
  margin-right: 30px;
  max-width: 700px;
  margin-left: -200px;
  padding: 30px 0px 50px 0px;
}

#mega_menu ul li:hover #mega {
  display: block;
  -webkit-animation-name: megaMenu;
  -webkit-animation-duration: 1s;
  animation-name: megaMenu;
  animation-duration: 1s;
} */

/* Define the keyframes for the animation */
/* @keyframes slideFromBottom {
  from {
    transform: translateY(3%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
} */

@keyframes slideFromBottom {
  from {
    transform: translateY(3%);
  }

  to {
    transform: translateY(0);
  }
}


/* Apply animation to the #mega element */
#mega {
  position: absolute;
  display: none;
  border: 1px solid rgba(211, 210, 210, 0.32);
  margin-top: 36px !important;
  z-index: 999999;
  background: #fff;
  padding: 15px 0;
  border-radius: 10px;
  margin-right: 30px;
  max-width: 700px;
  margin-left: -200px;
  padding: 30px 0px 50px 0px;
  animation: slideFromBottom 0.5s ease-in-out;
  /* Apply animation */
}

/* Show the #mega element when hovering over the parent li */
#mega_menuu ul li:hover #mega {
  display: block;
  /* margin-top: -10px;  */
  /* padding-top: 46px;  */
}

#mega_menuu #mega,
#menu1 {
  animation: slideFromBottom 0.5s ease-in-out;
}


#mega h3 {
  margin: 10px 0;
}

#mega ul li {
  display: block;
}

#mega ul li a {
  font-size: 14px;
  padding: 0 0 0 0px;
  line-height: 25px;
}

#mega ul li:hover {
  background-color: transparent;
}

#mega img {

  max-height: 150px;

}

@keyframes megaMenu {
  0% {
    opacity: 0;
    margin-top: 50px
  }

  20% {
    opacity: .25
  }

  50% {
    opacity: 1;
    margin-top: -2px
  }

  100% {
    margin-top: 0;
  }
}

/* Drop Down/Up Arrow for Mega Menu */

.dropdown {}

.dropbtn::after {

  transition: 0.2s;
  position: absolute;
  padding: 0px 6px 0px 6px;
}

.dropbtn::after:hover {
  transform: rotate(-180deg);
}

.dropdown:hover .dropbtn::after {
  transform: rotate(-180deg);
}

.dropbtn {
  font-size: 14px !important;
  padding: 0px 15px 0px 0px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  display: inline-block;
  font-weight: 100;
}



/* Navbar 2 */

#mega_menu1 {
  position: fixed;
  top: 80;
  left: 0;
  height: 5em;
  width: 100%;
  background: rgb(8, 13, 62);
  z-index: 400;
  transition: background-color 0.4s ease-out;

}

#mega_menu1.scroll {
  background-color: rgba(35, 32, 49, 0.812);
  color: black;
  height: 2em;
}

#mega_menu1.scroll a {
  font-weight: 700;
  color: rgb(251, 250, 250);
}


#mega_menu1 ul {
  list-style: none;
  padding: 0;
  margin: auto;
  text-align: left;
  position: relative;
}

#mega_menu1 ul li {
  display: inline-block;
}

#mega_menu1 ul li a {
  color: white;
  padding: 0 0px;
  font-size: inherit;
  line-height: 50px;
  display: block;
  text-decoration: none;
  font-style: inherit;
}

#mega_menu1 ul li:hover #mega1 {
  display: block;
  -webkit-animation-name: megaMenu1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  animation-name: megaMenu1;
  animation-duration: 1s;
}

@keyframes megaMenu1 {
  0% {
    opacity: 0;
    margin-top: 50px
  }

  20% {
    opacity: .25
  }

  50% {
    opacity: 1;
    margin-top: -2px
  }

  100% {
    margin-top: 0;
  }
}

/******** Sub menu *******/

#mega1 {
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.32);
  box-shadow: 0 8px 20px -2px rgba(0, 0, 0, 0.32);
  z-index: 999999;
  background: #fff;
  padding: 15px 0;
  border-radius: 10px;
}

#mega_menu ul li:hover #mega1 {
  display: block;
  -webkit-animation-name: megaMenu1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  animation-name: megaMenu1;
  animation-duration: 1s;
}

#mega1 h3 {
  margin: 10px 0;
}

#mega1 ul li {
  display: block;
}

#mega1 ul li a {
  font-size: 14px;
  padding: 0 0 0 15px;
  line-height: 25px;
}

#mega1 ul li:hover {
  background-color: transparent;
}

#mega1 img {
  margin-top: 15px;
  max-height: 150px;

}


/* our services */

.anim {
  -webkit-animation: scaledown 1s linear;
  -moz-animation: scaledown 1s linear;
  animation: scaledown 1s linear;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

.anim:hover {
  z-index: 100;
  -webkit-animation: scale 1s linear;
  -moz-animation: scale 1s linear;
  animation: scale 1s linear;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}



@keyframes scale {
  0% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(1.1);
    -webkit-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
  }
}

@keyframes scaledown {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.0);
  }
}


/* Font family home page header */

.head1 {
  /* font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif */
  font-family: Helvetica Neue, Arial, sans-serif;
}

.figtree {
  font-family: Figtree, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.text-charcoal500 {
  color: #405261 !important;
}

.text-charcoal400 {
  color: #748494 !important;
}

.headlineheight {
  line-height: 1.4;
}

.headlineheightt {
  line-height: 1.6;
}

.seasalt {
  background: rgb(76, 138, 207);
  background: linear-gradient(90deg, rgba(76, 138, 207, 0.6505952722886029) 0%, rgba(115, 151, 221, 0.4881302862942052) 50%, rgba(102, 151, 195, 0.12398462803089982) 100%);
}

.banpara {
  color: #8193b2;
}

.headbutton {
  background-color: 37b34a;
}

.texthome {
  font-family: "Bower", Georgia, "Times New Roman", serif;
  ;
}


.center {
  text-align: -webkit-center;
}

.textalign {
  text-align-last: center;
}

.textalignend {
  text-align-last: end;
}

/* Git Line */
.features-category--git-line-ending {
  border-bottom: solid 2px #e1e4e8;
  border-left: solid 2px #e1e4e8;
  border-bottom-left-radius: 16px;
}

.git-line-straight {
  border-bottom: solid 2px #dadfe9;
}

.git-line-straight-top {
  border-top: solid 2px #dadfe9;
  border-left: solid 2px #e1e4e8;
  border-top-left-radius: 16px;
  border-right: solid 2px #e1e4e8;
  border-top-right-radius: 16px;
}

.git-line-vertical {
  border-left: solid 2px #dadfe9;
}

.git-line-vertical2 {
  border-right: solid 3px #dadfe9;

}

.git-line-top {
  border-top: solid 2px #dadfe9;
  border-right: solid 2px #dadfe9;
  border-top-right-radius: 16px;
}

.divider {
  border-left: 1px solid black;
}


/* Git End */




/* parallax */

.parallax {
  /* The image used */
  background-image: url('assets/img/footerbg.jpg');

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* desktop technologies */

logo-slider {
  min-height: 100px;
  padding: 10px;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  overflow-x: hidden;
}

logo-slider:hover div {
  animation-play-state: paused;
}

logo-slider div {
  display: flex;
  position: relative;
  animation: marquee 30s linear infinite;
  min-width: 400px;

}

logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

}

@keyframes marquee {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(-430%);
  }
}

logo-slider2 {
  min-height: 200px;
  padding: 10px;
  overflow: hidden;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider2:hover div {
  animation-play-state: paused;
}

logo-slider2 div {
  display: flex;
  position: relative;
  animation: marquee1 30s linear infinite;
  min-width: 400px;

}

logo-slider2:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


@keyframes marquee1 {
  0% {
    transform: translateX(-510%);
  }

  100% {
    transform: translateX(20%);
  }
}


/* mobile view technologies */

logo-slider3 {
  min-height: 100px;
  padding: 10px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  overflow-x: hidden;
}

logo-slider3:hover div {
  animation-play-state: paused;
}

logo-slider3 div {
  display: flex;
  position: relative;
  animation: marquee3 30s linear infinite;
  min-width: 400px;

}

logo-slider3:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

}

@keyframes marquee3 {
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-510%);
  }
}

logo-slider4 {
  min-height: 200px;
  padding: 10px;
  overflow: hidden;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider4:hover div {
  animation-play-state: paused;
}

logo-slider4 div {
  display: flex;
  position: relative;
  animation: marquee4 30s linear infinite;
  min-width: 400px;

}

logo-slider4:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


@keyframes marquee4 {
  0% {
    transform: translateX(-410%);
  }

  100% {
    transform: translateX(0%);
  }
}

/* Globe css*/

#chartdiv {
  width: 80%;
  height: 500px;
  max-width: 100%;
  background-color: linear-gradient(90deg, rgba(8, 13, 62, 1) 0%, rgba(3, 5, 27, 1) 50%, rgba(9, 12, 42, 1) 100%);
}

/* card animation */
.actions-template-card {
  box-shadow: 0 1px 15px rgb(27 31 35 / 15%), 0 0 1px rgb(106 115 125 / 35%);
  transition: opacity 0.2s, transform .2s;
  transform: scale(1);
  max-height: 150px;
}

.cardcolor {
  color: #57606a;
}

/* box shadow */

.box-shadow-mktg-xl {
  box-shadow: 0 2.74416px 2.74416px rgb(0 0 0 / 3%), 0 5.48831px 5.48831px rgb(0 0 0 / 4%), 0 13.7208px 10.9766px rgb(0 0 0 / 5%), 0 20.5812px 20.5812px rgb(0 0 0 / 6%), 0 41.1623px 41.1623px rgb(0 0 0 / 7%), 0 96.0454px 89.1851px rgb(0 0 0 / 9%);
}

.shadow-longer {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09),
    0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09),
    0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
}

.iconbg {
  background: rgb(2, 171, 248);
  background: radial-gradient(circle, rgba(2, 171, 248, 1) 0%, rgba(2, 3, 248, 0.6533963927367823) 100%);

}

.box-shadow-card-border-mktg {
  box-shadow: 0 0 0 1px rgb(53 72 91 / 14%), 0 3px 2px rgb(0 0 0 / 4%), 0 7px 5px rgb(0 0 0 / 2%), 0 13px 10px rgb(0 0 0 / 2%), 0 22px 17px rgb(0 0 0 / 2%) !important;
}


/* Animation our service */

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

/* Circle Our Service */

.dot {
  height: 25px;
  width: 25px;
  background-color: black;
  border-radius: 50%;

}

/* box arrow our service */

.box {
  position: relative;
  float: left;
}

.box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 65px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid rgb(195, 191, 191);
  border-bottom: 15px solid transparent;

}


.bgimg {
  background-image: url("assets/img/bgelegantcarve.png");
  position: inherit;
}

.contactbg {
  background-color: #fdeae7;
}


.tabsection {
  border-radius: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(56, 152, 236, 0);
  box-shadow: 0 3px 6px -1px #888787, 0 -3px 5px 1px #fff;
}


.bgblues {
  background-color: #CDE1F4;
}

/* text gradient */

.texts {
  background: linear-gradient(90.91deg, #027bfd -24.67%, #9f51fb 53.55%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.graident-text {
  background: linear-gradient(89.99deg, #0452f4 -14.44%, #ce16c3 69.15%, #ef132c 93.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}



.app-card {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  border: 1px solid #ebeff3;
  border-radius: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 320px;
  justify-content: flex-start;
  padding: 18px;
  text-decoration: inherit;
  -webkit-transition: all .2s;
  transition: all .2s;
  width: 100%;
}

.wrapper {
  overflow: auto;
  width: 100%;
  margin-left: 15px;
}

.wrapper1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 25%;
}

/* Review Star */

.clip-star {
  background: gold;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  display: inline-block;
  height: 16px;
  width: 16px;

}

/* Review Star main testimonials */

.clip-star1 {
  background: rgb(12, 224, 76);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  display: inline-block;
  ;
  height: 24px;
  width: 24px;

}

.pointer {
  cursor: pointer;
}

.shad1 {
  box-shadow: 0 8px 14px 0 rgb(213 220 225 / 50%)
}

/* nav header border*/

.bottom {
  border-color: rgba(212, 210, 210, 0.122);
}

.tag-new {
  font-size: .875rem;
  line-height: 1.375rem;
  font-family: neue-haas-grotesk-text, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #3b3f47;
  background-color: #25c16f;
}




/* Services Home Page*/

.pricing-box {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 4px 40px rgb(9 54 109 / 8%);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.pricing_inner {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.pricing_yellow_bg {
  background: linear-gradient(180deg, hsla(38, 71%, 93%, .6), hsla(38, 71%, 93%, 0) 98.53%);
}

.text-earth {
  color: #f58220 !important;
}

.pricing_blue_bg {
  background: linear-gradient(180deg, rgba(236, 243, 255, .8), rgba(236, 243, 255, 0) 98.53%);
}

.text-blue {
  color: #06409c !important;
}

.bgg-blue {
  background-color: #06409c !important;
}

.text-blue1 {
  color: #535353 !important;
}

.pricing_red_bg {
  background: linear-gradient(180deg, rgba(255, 236, 236, 0.8), rgba(236, 243, 255, 0) 98.53%);
}

.text-red {
  color: #ff0505 !important;
}

.pricing_green_bg {
  background: linear-gradient(180deg, rgba(236, 255, 240, 0.8), rgba(236, 243, 255, 0) 98.53%);
}

.text-green {
  color: #3c6840 !important;
}

.text-green1 {
  color: #30b671 !important;
}


.texthome {
  font-family: "Bower", Georgia, "Times New Roman", serif;
}

.textpara {
  font-family: sans, Helvetica;
}

.anim,
.anim svg {
  position: relative;
  width: 87%;
  height: 100%;
  margin-top: 0px;
  margin-left: 50px;
}

.anim path {
  stroke-width: 2;
}

/** Mobile Menu **/

#menuToggle1 {
  display: block;
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
}

#menuToggle1 input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle1 span {
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle1 span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle1 span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle1 input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #f6f4f4;
}

#menuToggle1 input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle1 input:checked~span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#menu1 {
  position: absolute;
  width: 425px;
  height: 800px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 125px;
  right: -130px;
  background: #1f384b;
  border: 1px solid #ccc;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu1 li {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

#menuToggle1 input:checked~ul {
  transform: scale(1.0, 1.0);
  opacity: 1;
}

/* mobile sidemenu */


.checkbox-container {
  display: block;
  float: right;
  margin-top: 0px;
  margin-right: -25px;
  height: 10px;
  position: relative;
  background: #182825;
}

.checkbox-trigger {
  opacity: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  cursor: pointer;
  z-index: 5;
}

.hamburger-menu,
.hamburger-menu::before,
.hamburger-menu::after {
  display: block;
  position: absolute;
  background: rgb(126, 217, 86);
  width: 25px;
  height: 4px;
  margin: 1.3em 3em;
  transition: background 0.3s;
}

.hamburger-menu::before,
.hamburger-menu::after {
  content: '';
  position: absolute;
  margin: -0.7em 0 0;
  transition: width 0.7s ease 0.3s, transform 0.7s ease 0.3s;
}

.hamburger-menu::after {
  margin-top: 0.7em;
}

.hamburger-menu {
  position: relative;
  display: block;
  margin: 0;
  margin-top: 1.45em;
  margin-right: 1.35em;
  margin-left: 0.35em;
  margin-bottom: 1.45em;
  transition: width 0.3s ease;
}

.checkbox-trigger:checked {
  left: 307px;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu {
  width: 2em;

  transition: width 0.7s ease 0.7s;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu::before {
  width: 1.2em;
  transform: rotate(-35deg);
  margin-top: -0.4em;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu::after {
  width: 1.2em;
  transform: rotate(35deg);
  margin-top: 0.4em;
}

.checkbox-trigger:checked+.menu-content ul {
  width: 305px;
  height: 800px;
  transition: width 0.7s ease 0.3s, height 0.3s ease;
}

.menu-content {
  display: flex;
  background: #fff;

  float: left;
}

.menu-content ul {
  display: block;
  padding-left: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s ease 0.7s, width 0.7s ease;
}

.menu-content ul li {
  list-style: none;
  cursor: pointer;
  transition: color 0.5s, background 0.5s;
  padding-top: 15px;
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .app-card {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgb(255, 255, 255);
    border: 1px solid #ebeff3;
    border-radius: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 420px;
    justify-content: flex-start;
    padding: 18px;
    text-decoration: inherit;
    -webkit-transition: all .2s;
    transition: all .2s;
    width: 100%;
  }
}

.maps {
  max-width: 1510;
  max-height: 450;
}

/* Drpdown animation */

.menu-item {
  position: relative;
  display: inline-block;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slideUp 0.4s ease-in-out forwards;
}


@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slideLeft 0.4s ease-in-out forwards;
}

@keyframes slideUpp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-upp {
  animation: slideUpp 0.4s ease-in-out forwards;
}
